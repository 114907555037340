body,
html {
  margin: 0;
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.titbtn {
  background-color: rgba(29, 65, 122, 1);
  border-radius: 9px;
  margin-left: 25px;
  color: #ffff;
  padding: 5px 15px;
  font-size: 18px;
  min-width: 115px;
}
.btn:hover {
  color: #ffff;
}
.boxform {
  min-height: 450px;
  background: #ffff;
  margin-top: 20px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
}
.boxform1 {
  min-height: 100px;
  background: #ffff;
  margin-top: 20px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  margin-bottom: 10px;
}
.data input {
  margin-left: 10px;
  padding-bottom: 10px;
  width: 234px;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid #373759;
}
.data label {
  min-width: 100px;
}
.ntbox {
  padding: 78px;
  max-width: 620px;
  background: #f9fbfd;
  margin: 0 auto;
  border-radius: 10px;
}
.formbtn {
  top: -50px !important;
  margin-right: 16px;
  background-color: rgba(29, 65, 122, 1);
  color: #ffff;
  border-radius: 6px;
  font-size: 12px;
  min-width: 50px;
  padding: 5px 20px;
}
.downloadbtn {
  margin-right: 16px;
  background-color: rgba(29, 65, 122, 1);
  color: #ffff;
  border-radius: 6px;
  font-size: 12px;
  min-width: 50px;
  padding: 5px 20px;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn1 {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 20px;
  color: rgba(29, 65, 122, 1);
  padding: 5px 38px;
  font-size: 16px;
  min-width: 80px;
  border-color: rgba(29, 65, 122, 1);
}
.downloadfinalresults {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 20px;
  color: rgba(29, 65, 122, 1);
  padding: 5px 8px;
  font-size: 16px;
  min-width: 80px;
  border-color: rgba(29, 65, 122, 1);
}
.submitallsteps {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 20px;
  color: rgba(29, 65, 122, 1);
  padding: 5px 10px;
  font-size: 16px;
  min-width: 80px;
  border-color: rgba(29, 65, 122, 1);
}
.submitstep {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 20px;
  color: rgba(29, 65, 122, 1);
  padding: 5px 30px;
  font-size: 16px;
  min-width: 80px;
  border-color: rgba(29, 65, 122, 1);
}
.close {
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 20px;
  color: rgba(29, 65, 122, 1);
  padding: 4px 35px;
  font-size: 16px;
  min-width: 198px;
  border-color: rgba(29, 65, 122, 1);
}
.box1 {
  min-height: 300px;
  background: #f9fbfd;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
}
.custom-file-upload {
  border: 1px solid #1d417a;
  border-radius: 3px;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 20px;
  font-size: 10px;
  text-align: center;
  background: #1d417a;
  color: #fff;
}
#action {
  margin-left: 10px;
  width: 103px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  padding-top: 5px;
  font-size: 13px;
  border: 1px solid #373759;
}
.fileContainer input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.fileContainer {
  background: #fff;
  position: relative;
  border-radius: 12px;
  border: 1px solid rgba(226, 226, 226, 1);
  padding: 15px;
  height: 59px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
#matchType {
  margin-left: 10px;
  width: 200px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
  padding-top: 5px;
  font-size: 13px;
  border: 1px solid #373759;
}
.Dashboardheading {
  font-size: 14px;
  padding-top: 25px;
  /* letter-spacing: .035em; */
  font-family: "Montserrat";
  line-height: 16px;
  text-align: center;
}
.Table-headers {
  color: rgb(29, 65, 122);
}
.next {
  background-color: rgba(29, 65, 122, 1);
  color: #ffff;
  border-radius: 8px;
  margin-left: 20px;
  /* color: rgba(29, 65, 122, 1); */
  padding: 5px 38px;
  font-size: 12px;
  min-width: 50px;
  /* border-color: rgba(29, 65, 122, 1); */
}
.bg-black {
  background-color: white;
}
.bg-blue {
  background-color: blue;
}
